import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

function App() {
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [values, setValues] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });
  const { name, subject, email, message } = values;

  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptchaStatus(value);
  }

  const formRender = () => {
    return (
      <React.Fragment>
        <form
          action={
            name &&
            subject &&
            email &&
            message &&
            captchaStatus &&
            `https://getform.io/f/f6a46b57-0dc4-48eb-98be-74c75f829d50`
          }
          method={
            name && subject && email && message && captchaStatus ? "post" : null
          }
          // onSubmit={handleSubmit}
          data-aos='fade'
        >
          {/* <div class="form-group row">
            <div class="col-md-6 mb-3 mb-lg-0">
              <input
                type="text"
                class="form-control"
                placeholder="First name"
                name="FirstName"
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                placeholder="Last name"
                name="LastName"
              />
            </div>
          </div> */}
          <div className='form-group row'>
            <div className='col-md-12'>
              <input
                type='text'
                className='form-control'
                placeholder='Name'
                name='name'
                value={values.name}
                onChange={handleChange()}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12'>
              <input
                type='text'
                className='form-control'
                placeholder='Subject'
                name='subject'
                value={values.subject}
                onChange={handleChange()}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12'>
              <input
                type='email'
                className='form-control'
                placeholder='Email'
                name='email'
                value={values.email}
                onChange={handleChange()}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                id
                cols={30}
                rows={10}
                placeholder='Write your message here.'
                name='message'
                value={values.message}
                onChange={handleChange()}
              />
            </div>
          </div>

          <div className='form-group row'>
            <div className='col-md-6'>
              {/* {formStatus ? (
                <div className="alert alert-success">
                  Your message has been sent.
                </div>
              ) : (
                ""
              )} */}
              <ReCAPTCHA
                sitekey='6LeJd7oaAAAAALItRWPL3MtTbKjLVNFIY8HnVN9U'
                onChange={onChange}
              />
              <button
                type='submit'
                className='btn btn-primary py-3 px-5 btn-block'
              >
                {" "}
                Submit
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <div className='site-wrap'>
        <div className='site-mobile-menu site-navbar-target'>
          <div className='site-mobile-menu-header'>
            <div className='site-mobile-menu-close mt-3'>
              <span className='icon-close2 js-menu-toggle' />
            </div>
          </div>
          <div className='site-mobile-menu-body' />
        </div>
        <header
          className='site-navbar py-4 js-sticky-header site-navbar-target'
          role='banner'
        >
          <div className='container-fluid'>
            <div className='d-flex align-items-center'>
              <div className='site-logo'>
                <a href='index.html'>CRASS Infotech</a>
              </div>
              <div>
                <nav
                  className='site-navigation position-relative text-right'
                  role='navigation'
                >
                  <ul className='site-menu main-menu js-clone-nav mr-auto d-none d-lg-block'>
                    <li>
                      <a href='#home-section' className='nav-link'>
                        Home
                      </a>
                    </li>
                    {/* <li><a href="#work-section" class="nav-link">Work</a></li> */}
                    <li>
                      <a href='#process-section' className='nav-link'>
                        Process
                      </a>
                    </li>
                    <li>
                      <a href='#divisions-section' className='nav-link'>
                        Divisions
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className='ml-auto'>
                <nav
                  className='site-navigation position-relative text-right'
                  role='navigation'
                >
                  <ul className='site-menu main-menu site-menu-dark js-clone-nav mr-auto d-none d-lg-block'>
                    <li className='cta'>
                      <a href='#contact-section' className='nav-link'>
                        <span className='rounded border border-primary'>
                          Contact
                        </span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <a
                  href='#'
                  className='d-inline-block d-lg-none site-menu-toggle js-menu-toggle text-black float-right'
                >
                  <span className='icon-menu h3' />
                </a>
              </div>
            </div>
          </div>
        </header>
        <div className='intro-section' id='home-section'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-4 mr-auto' data-aos='fade-up'>
                <h1>We build interactives.</h1>
                <p className='mb-5'>
                  CRASS Infotech is one of the earliest and leading video game
                  publisher in South Asia.
                </p>
                <p>
                  <a
                    href='#contact-section'
                    className='btn btn-outline-light py-3 px-5'
                  >
                    Get Started
                  </a>
                </p>
              </div>
              <div
                className='col-lg-2 ml-auto'
                data-aos='fade-up'
                data-aos-delay={100}
              >
                <figure className='img-absolute'>
                  <img
                    src='images/person_1.jpg'
                    alt='Image'
                    className='img-fluid'
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className='site-section section-1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-5 mr-auto mb-5'>
                <div className='mb-5'>
                  <span className='section-sub-title d-block'>
                    About CRASS Infotech
                  </span>
                  <h2 className='section-title'>
                    We Value More on Quality and not in Quantity.
                  </h2>
                  <p>
                    We work with independent developers, studios of small and
                    large scale , distributors and retailers in bringing the
                    best possible interactive entertainment to our audience.
                  </p>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div
                      className='counter d-flex align-items-start mb-5'
                      data-aos='fade-up'
                      data-aos-delay
                    >
                      <div className='icon-wrap'>
                        <span className='flaticon-reload text-primary' />
                      </div>
                      <div className='counter-text'>
                        <strong>11</strong>
                        <span>Projects Completed</span>
                      </div>
                    </div>
                    <div
                      className='counter d-flex align-items-start'
                      data-aos='fade-up'
                      data-aos-delay={100}
                    >
                      <div className='icon-wrap'>
                        <span className='flaticon-download text-primary' />
                      </div>
                      <div className='counter-text'>
                        <strong>117K</strong>
                        <span>Supporters </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div
                      className='counter d-flex align-items-start mb-5'
                      data-aos='fade-up'
                      data-aos-delay={200}
                    >
                      <div className='icon-wrap'>
                        <span className='flaticon-monitor text-primary' />
                      </div>
                      <div className='counter-text'>
                        <strong>47</strong>
                        <span>Partners</span>
                      </div>
                    </div>
                    <div
                      className='counter d-flex align-items-start'
                      data-aos='fade-up'
                      data-aos-delay={300}
                    >
                      <div className='icon-wrap'>
                        <span className='flaticon-chat text-primary' />
                      </div>
                      <div className='counter-text'>
                        <strong>920</strong>
                        <span>Distributors</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='image-absolute-box'>
                  <div className='box' data-aos='fade-up'>
                    <div className='icon-wrap'>
                      <span className='flaticon-vector' />
                    </div>
                    <h3>Creativity is the key</h3>
                    <p>
                      Developing ideas for the purpose of solving problems and
                      exploiting opportunities.
                    </p>
                  </div>
                  <img
                    src='images/about_1.jpg'
                    alt='Image'
                    className='img-fluid'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
<div class="site-section section-2" id="work-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mb-5">
        <span class="section-sub-title d-block">Excellent Work</span>
        <h2 class="section-title">Our Works</h2>
        <p>
          CRASS Infotech is a video game publisher bringing interactive entertainment for PC, Mac, Linux, Xbox, Playstation.
        </p>
      </div>
    </div>
  </div>

  <div class="owl-carousel nonloop-block-13">
    <a
      class="work-thumb"
      href="images/about_1.jpg"
      data-fancybox="gallery"
    >
      <div class="work-text">
        <h3>Work Name Here</h3>
        <span class="category">Website</span>
      </div>
      <img src="images/about_1.jpg" alt="Image" class="img-fluid" />
    </a>

    <a
      class="work-thumb"
      href="images/slide_1.jpg"
      data-fancybox="gallery"
    >
      <div class="work-text">
        <h3>Work Name Here</h3>
        <span class="category">Illustration</span>
      </div>
      <img src="images/slide_1.jpg" alt="Image" class="img-fluid" />
    </a>

    <a
      class="work-thumb"
      href="images/slide_2.jpg"
      data-fancybox="gallery"
    >
      <div class="work-text">
        <h3>Work Name Here</h3>
        <span class="category">Branding</span>
      </div>
      <img src="images/slide_2.jpg" alt="Image" class="img-fluid" />
    </a>

    <a
      class="work-thumb"
      href="images/slide_3.jpg"
      data-fancybox="gallery"
    >
      <div class="work-text">
        <h3>Work Name Here</h3>
        <span class="category">Web Development</span>
      </div>
      <img src="images/slide_3.jpg" alt="Image" class="img-fluid" />
    </a>

    <a
      class="work-thumb"
      href="images/slide_4.jpg"
      data-fancybox="gallery"
    >
      <div class="work-text">
        <h3>Work Name Here</h3>
        <span class="category">Design</span>
      </div>
      <img src="images/slide_4.jpg" alt="Image" class="img-fluid" />
    </a>
  </div>
</div> */}
        <div className='site-section section-2' id='process-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 mb-5'>
                <span className='section-sub-title d-block'>Our Process</span>
                <h2 className='section-title'>Our Process</h2>
                <p>
                  We have a very exquisite and systematic way of bringing a
                  project to the market.
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4' data-aos='fade-up' data-aos-delay>
                <div className='process p-3'>
                  <span className='number'>01</span>
                  <div>
                    <span className='flaticon-glasses display-4 text-primary mb-4 d-inline-block' />
                    <h3>Research</h3>
                    <p>
                      Our first process involves the collection, organization,
                      and analysis of information to increase understanding of
                      the product and its market.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4' data-aos='fade-up' data-aos-delay={100}>
                <div className='process p-3'>
                  <span className='number'>02</span>
                  <div>
                    <span className='flaticon-vector display-4 text-primary mb-4 d-inline-block' />
                    <h3>Building</h3>
                    <p>
                      The most important part is to build the project which
                      involves us to work closely with our developers .
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4' data-aos='fade-up' data-aos-delay={200}>
                <div className='process p-3'>
                  <span className='number'>03</span>
                  <div>
                    <span className='flaticon-monitor display-4 text-primary mb-4 d-inline-block' />
                    <h3>Launch</h3>
                    <p>
                      Now its time to release our product to the market. With
                      the help of our partners spread all around the world we
                      make it reach to a wide audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='site-section section-1'>
          <div class='container'>
            <div class='row'>
              <div class='col-lg-5 mr-auto mb-5'>
                <div class='mb-5'>
                  <span class='section-sub-title d-block'>The Founder</span>
                  <h2 class='section-title'>Siddhant Dixit</h2>
                  <p>
                    "Each Day is an opportunity to grow and be better. Stay calm
                    and focused. Observe, analyze and learn from every person,
                    conversation, story, success, failure and setback. Never let
                    the passion for learning die."
                  </p>
                </div>

                <div class='row'></div>
              </div>

              <div class='col-lg-6'>
                <div class='image-absolute-box'>
                  <img
                    src='images/siddhant_dixit.jpg'
                    alt='Image'
                    class='img-fluid'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='site-section bg-light' id='divisions-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 mb-5'>
                <span className='section-sub-title d-block'>Subsidiaries</span>
                <h2 className='section-title'>Divisions</h2>
                <p>
                  CRASS Group of Companies has multiple subsidiaries working in
                  different industries.
                </p>
              </div>
            </div>
          </div>
          <div className='owl-carousel nonloop-block-14'>
            <div className='service'>
              <div>
                <span className='flaticon-reload display-4 text-primary mb-4 d-inline-block' />
                <h3>CRASS Studios</h3>
                {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
          possimus distinctio ex. Natus totam voluptatibus animi
          aspernatur ducimus quas obcaecati mollitia quibusdam temporibus
          culpa dolore molestias blanditiis consequuntur sunt nisi.
        </p> */}
              </div>
            </div>
            <div className='service'>
              <div>
                <span className='flaticon-download display-4 text-primary mb-4 d-inline-block' />
                <h3>CRASS Music</h3>
                {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
          possimus distinctio ex. Natus totam voluptatibus animi
          aspernatur ducimus quas obcaecati mollitia quibusdam temporibus
          culpa dolore molestias blanditiis consequuntur sunt nisi.
        </p> */}
              </div>
            </div>
            <div className='service'>
              <div>
                <span className='flaticon-monitor display-4 text-primary mb-4 d-inline-block' />
                <h3>CRASS Games</h3>
                {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
          possimus distinctio ex. Natus totam voluptatibus animi
          aspernatur ducimus quas obcaecati mollitia quibusdam temporibus
          culpa dolore molestias blanditiis consequuntur sunt nisi.
        </p> */}
              </div>
            </div>
            <div className='service'>
              <div>
                <span className='flaticon-chat display-4 text-primary mb-4 d-inline-block' />
                <h3>CRASS Club</h3>
                {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
          possimus distinctio ex. Natus totam voluptatibus animi
          aspernatur ducimus quas obcaecati mollitia quibusdam temporibus
          culpa dolore molestias blanditiis consequuntur sunt nisi.
        </p> */}
              </div>
            </div>
            <div className='service'>
              <div>
                <span className='flaticon-glasses display-4 text-primary mb-4 d-inline-block' />
                <h3>CRASS Bee</h3>
                {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
          possimus distinctio ex. Natus totam voluptatibus animi
          aspernatur ducimus quas obcaecati mollitia quibusdam temporibus
          culpa dolore molestias blanditiis consequuntur sunt nisi.
        </p> */}
              </div>
            </div>
            <div className='service'>
              <div>
                <span className='flaticon-vector display-4 text-primary mb-4 d-inline-block' />
                <h3>CRASS Wears</h3>
                {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
          possimus distinctio ex. Natus totam voluptatibus animi
          aspernatur ducimus quas obcaecati mollitia quibusdam temporibus
          culpa dolore molestias blanditiis consequuntur sunt nisi.
        </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className='site-section' id='contact-section'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-7'>
                <h2 className='section-title mb-3'>Message Us</h2>
                <p className='mb-5'>
                  Drop us a line and we will get back to you very soon.
                </p>
                {formRender()}
              </div>
            </div>
          </div>
        </div>
        <footer className='footer-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4'>
                <h3>About CRASS Infotech</h3>
                <p>
                  We are a company based in India. Our expertise on game
                  publishing can help you bring your game to the masses and we
                  thrive to bring the best results to every 'Interactive
                  Entertainment' creation we get to work on.
                </p>
              </div>
              <div className='col-md-3 ml-auto'>
                <h3>Links</h3>
                <ul
                  className='list-unstyled footer-links d-flex justify-content-around'
                  style={{ fontSize: "2em" }}
                >
                  <li>
                    <a href='https://twitter.com/CRASS_Infotech'>
                      <i className='mdi mdi-twitter' />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.facebook.com/crassinfotech/'>
                      <i className='mdi mdi-facebook' />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.youtube.com/channel/UCHWvMSs50LfUvfisQhGZP5A'>
                      <i className='mdi mdi-youtube' />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.linkedin.com/company/crass'>
                      <i className='mdi mdi-linkedin' />
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div class="col-md-4">
        <h3>Subscribe</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Nesciunt incidunt iure iusto architecto? Numquam, natus?
        </p>
        <form action="#">
          <div class="d-flex mb-5">
            <input
              type="text"
              class="form-control rounded-0"
              placeholder="Email"
            />
            <input
              type="submit"
              class="btn btn-primary rounded-0"
              value="Subscribe"
            />
          </div>
        </form>
      </div> */}
            </div>
            <div className='row pt-5 mt-5 text-center'>
              <div className='col-md-12'>
                <div className='border-top pt-5'>
                  <p>
                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                    Copyright © CRASS Infotech. All rights reserved.
                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default App;
